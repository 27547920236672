<script setup>
const { data, error } = await useCmsFetch('entry/index')

if (error.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    fatal: true,
  })
}

useHead({
  title: 'Vandstrom | ' + data.value?.seoTitle || data.value?.meta.title,
  meta: [
    { property: 'og:title', content: 'Vandstrom | ' + data.value?.seoTitle || data.value?.title },
    { name: 'description', content: data.value?.seoDescription },
    { name: 'robots', content: data.value?.seoRobots },
  ].filter((item) => item.content),
  link: [{ rel: 'canonical', href: data.value?.seoCanonical || useRequestURL().href }].filter(
    (item) => item.href,
  ),
})
onMounted(() => {
  // console.log("mounted home", data.value, error.value);
})
const route = useRoute()
const isOverlay = computed(() => route.meta.overlayPage)
</script>

<template>
  <div>
    <client-only>
      <!-- <transition name="fade" mode="out-in"> -->
      <globals-header class="pl-bleed pr-bleed" v-if="!isOverlay" />
      <!-- </transition> -->
    </client-only>
    <div class="index" v-if="data">
      <div class="page-content">
        <component-sections v-if="data" :sections="data.sections" />
      </div>
    </div>
    <globals-footer class="pl-bleed pr-bleed" />
  </div>
</template>
